<template>
    <div>
        <b-container v-if="loader">
            <div class="mb-3">
                <Spinner size="medium" line-fg-color="#e91e63" />
            </div>
        </b-container>
        <b-container v-else>
            <section v-if="getLatestInterviews.length != 0 && !loader" class="interviews pt-0">
                <div class="sector pb-0 mb-0">
                    <h2 class="main-title">Latest Interviews</h2>
                    <p class="main-subtitle">Keep up with the latest interviews</p>
                    <b-row>
                        <b-col v-for="interview in getLatestInterviews" :key="interview.id" cols="12" lg="6">
                            <div class="interviews__element">
                                <div v-if="interview.new" class="custom-ribbon custom-ribbon--right">
                                    <span class="custom-ribbon__text custom-ribbon__text--marina">New</span>
                                </div>
                                <div class="interviews__content">
                                    <span class="interviews__date"><span class="agencies__star-tooltip" v-b-tooltip title="Interview date"><simple-line-icons icon="calendar" size="small" color="#504e70" /></span>{{ interview.date }}</span><br class="d-inline d-sm-none">
                                    <span class="interviews__person"><span class="agencies__star-tooltip" v-b-tooltip title="Interviewee"><simple-line-icons icon="user" size="small" color="#504e70" /></span>{{ interview.interviewee }}</span>
                                    <p v-line-clamp:20="1" class="interviews__company">{{ interview.company }}</p>
                                    <h2 v-if="desktopMode" class="interviews__title" v-line-clamp:24="2">{{ interview.title }}</h2>
                                    <h2 v-else class="interviews__title" v-line-clamp:24="0">{{ interview.title }}</h2>
                                </div>
                                <div class="events__details">
                                    <router-link class="details-button details-button--full" :to="{ name: 'interview', params: { id: interview.id }}"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Read more</router-link>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center mb-4">
                        <router-link to="/interviews" class="details-button details-button--red"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />View more</router-link>
                    </b-row>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import Spinner from 'vue-simple-spinner'
export default {
  components: {
    SimpleLineIcons,
    Spinner
  },
  data: function () {
    return {
      loader: true,
      screenWidth: 0,
      desktopMode: false
    }
  },
  created () {
    setTimeout(function () { this.loader = false }.bind(this), 1500)

    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize () {
      this.screenWidth = window.innerWidth
      if (this.screenWidth > 768) {
        this.desktopMode = true
      } else {
        this.desktopMode = false
      }
    }
  },
  computed: {
    getLatestInterviews () {
      var data = require('../assets/json/interviews.json')
      var latest = Object.values(data).reverse().slice(0, 4)
      return latest
    }
  }
}
</script>
